import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import { FormattedMessage } from "react-intl"

import Layout from "../../components/layout/index"
import SEO from "../../components/seo"
import useLangs from "../../hooks/useLangs"
import StyledSectionBox from "../../components/sectionBox/index"
import { StyledContainer } from "../../components/containers/index"

const StyledProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 32px;
  margin: 24px 0 64px;
`

const StyledProduct = styled(Link)`
  text-align: center;
  display: inline-block;
  text-decoration: none;
  color: ${props => props.theme.colors.dark100};

  p {
    margin-top: 12px;
    margin-bottom: 0;
  }

  :hover {
    text-decoration: underline;
  }
`

const ProductsPageEn = ({
  location,
  data: { site, background, prod1, prod2, prod3, prod4, prod5, prod6, prod7 },
}) => {
  const { localLink } = useLangs(location.pathname, site.siteMetadata.languages)

  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="en-US"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="products"
      />
      <StyledContainer>
        <h3>
          <FormattedMessage id="products" />
        </h3>
      </StyledContainer>
      <StyledContainer>
        <StyledProductsGrid>
          <StyledProduct to={`${localLink}products/transfer-parts`}>
            <Img fluid={prod1.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="transferParts" />
            </p>
          </StyledProduct>
          <StyledProduct to={`${localLink}products/progressive-parts`}>
            <Img fluid={prod2.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="progressiveParts" />
            </p>
          </StyledProduct>
          <StyledProduct to={`${localLink}products/multi-slide-bending-parts`}>
            <Img fluid={prod3.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="multiSlideBendingParts" />
            </p>
          </StyledProduct>
          <StyledProduct
            to={`${localLink}products/single-operation-and-hydraulic-pressing-parts`}
          >
            <Img fluid={prod4.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="singleOperationHydraulicPressing" />
            </p>
          </StyledProduct>
          <StyledProduct
            to={`${localLink}products/polished-and-coated-products`}
          >
            <Img fluid={prod5.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="polishedCoatedProducts" />
            </p>
          </StyledProduct>
          <StyledProduct to={`${localLink}products/nc-machining-parts`}>
            <Img fluid={prod6.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="NCMachiningParts" />
            </p>
          </StyledProduct>
          <StyledProduct to={`${localLink}products/die-and-tooling-parts`}>
            <Img fluid={prod7.childImageSharp.fluid} />
            <p>
              <FormattedMessage id="DieToolingParts" />
            </p>
          </StyledProduct>
        </StyledProductsGrid>
      </StyledContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        languages {
          langs
          defaultLangKey
        }
      }
    }
    background: file(relativePath: { eq: "products/products-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod1: file(relativePath: { eq: "products/transfer-parts-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod2: file(
      relativePath: { eq: "products/progressive-parts-featured.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod3: file(relativePath: { eq: "products/bending-parts-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod4: file(
      relativePath: { eq: "products/single-operation-featured.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod5: file(relativePath: { eq: "products/polished-coated-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod6: file(
      relativePath: { eq: "products/nc-machining-parts-featured.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prod7: file(
      relativePath: { eq: "products/die-tooling-parts-featured.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 320, maxHeight: 210) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ProductsPageEn
